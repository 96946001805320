<template>
  <div class="container-fluid p-0 h-100 d-flex flex-column">
    <div class="text-center pb-3">
      <h2>{{ $t("REPORTING_SETUP") }}</h2>
    </div>
    <div
      class="flex-full-height router-view-scrollable-content"
    >
      <div class="row setup-container justify-content-center">
        <div class="col-6">
          <h4>{{ $t("REPORTING_SETUP_TARGET") }}</h4>
          <p class="setup-question-container">
            {{ $t("REPORTING_SETUP_TARGET_TEXT1") }}
            <Switch
              :initValue="reportingSetUp.displayTarget"
              :disabled="activeWaveReadonly"
              @change="
                updateReportingSetUp(
                  $event.target.checked,
                  reportingSetUp,
                  'displayTarget'
                )"
            />
          </p>
          <p>{{ $t("REPORTING_SETUP_TARGET_TEXT2") }}</p>
          
          <!-- CSV_BEV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("CSV_BEV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.csvePerfomanceBEVTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'csvePerfomanceBEVTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{
                parseInt(reportingSetUp.csvePerfomanceBEVTarget * 100)
              }}%</span
            >
          </div>
          <!-- VIRTUAL_CONSULTATION -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("VIRTUAL_CONSULTATION") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.virtualConsultationTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'virtualConsultationTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage">
              {{ parseInt(reportingSetUp.virtualConsultationTarget * 100) }}%
            </span>
          </div>
          <!-- CSV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("CSV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.csvConventionalEngineTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'csvConventionalEngineTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{
                parseInt(reportingSetUp.csvConventionalEngineTarget * 100)
              }}%</span
            >
          </div>
          <!-- CSV_PHEV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("CSV_PHEV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.csvePerfomancePHEVTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'csvePerfomancePHEVTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{
                parseInt(reportingSetUp.csvePerfomancePHEVTarget * 100)
              }}%</span
            >
          </div>
          <!-- STOCK_CAR_BEV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("STOCK_CAR_BEV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.stockCarBEVTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'stockCarBEVTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{ parseInt(reportingSetUp.stockCarBEVTarget * 100) }}%</span
            >
          </div>
          <!-- STOCK_CAR -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("STOCK_CAR") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.stockcarTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'stockcarTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{ parseInt(reportingSetUp.stockcarTarget * 100) }}%</span
            >
          </div>
          <!-- STOCK_CAR_PHEV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("STOCK_CAR_PHEV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.stockCarPHEVTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'stockCarPHEVTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{ parseInt(reportingSetUp.stockCarPHEVTarget * 100) }}%</span
            >
          </div>
          <!-- UV_BEV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("UV_BEV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.uvbevTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'uvbevTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{ parseInt(reportingSetUp.uvbevTarget * 100) }}%</span
            >
          </div>
          <!-- UV_BEV_VIRTUAL -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("UV_BEV_VIRTUAL") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.uvbevVirtualTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'uvbevVirtualTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage">
              {{ parseInt(reportingSetUp.uvbevVirtualTarget * 100) }}%
            </span>
          </div>
          <!-- UV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("UV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.uvConventionalEngineTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'uvConventionalEngineTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{
                parseInt(reportingSetUp.uvConventionalEngineTarget * 100)
              }}%</span
            >
          </div>
          <!-- UV_ICE_VIRTUAL -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("UV_ICE_VIRTUAL") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.uviceVirtualTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'uviceVirtualTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage">
              {{ parseInt(reportingSetUp.uviceVirtualTarget * 100) }}%
            </span>
          </div>
          <!-- UV_ICE_VIRTUAL_OMNICHANNEL -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("UV_ICE_VIRTUAL_OMNICHANNEL") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.uviceVirtualOmnichannelTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'uviceVirtualOmnichannelTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage">
              {{ parseInt(reportingSetUp.uviceVirtualOmnichannelTarget * 100) }}%
            </span>
          </div>
          <!-- UV_PHEV -->
          <div class="setup-slider-container py-1">
            <span class="setup-slider-text">{{ $t("UV_PHEV") }}</span>
            <Slider
              class="slider"
              :initValue="reportingSetUp.uvphevTarget"
              :disabled="!reportingSetUp.displayTarget || activeWaveReadonly"
              :steps="0.05"
              @change="
                updateReportingSetUp(
                  parseFloat($event.target.value),
                  reportingSetUp,
                  'uvphevTarget'
                )"
              :key="rerenderKey"
            />
            <span class="setup-slider-percentage"
              >{{ parseInt(reportingSetUp.uvphevTarget * 100) }}%</span
            >
          </div>
        </div>
        <div class="col-6" v-show="false">
          <h4>{{ $t("REPORTING_SETUP_UPLOADS") }}</h4>
          <p class="setup-question-container">
            {{ $t("REPORTING_SETUP_UPLOADS_TEXT1") }}
            <Switch
              :initValue="reportingSetUp.showUploads"
              :disabled="activeWaveReadonly"
              @change="
                updateReportingSetUp(
                  $event.target.checked,
                  reportingSetUp,
                  'showUploads'
                )"
            />
            <i
              class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
            >
              <span class="tooltiptext"
                >Uploads like business cards, price quotes, configurations etc.
                received during the consultation</span
              >
            </i>
          </p>

          <h4>{{ $t("REPORTING_SETUP_CONTACT") }}</h4>
          <p>{{ $t("REPORTING_SETUP_CONTACT_TEXT") }}</p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="setupContactRadios"
              id="setupContactRadio0"
              :disabled="activeWaveReadonly"
              :checked="reportingSetUp.contactdataDisplay == 0"
              :value="0"
              @change="
                updateReportingSetUp(
                  parseInt($event.target.value),
                  reportingSetUp,
                  'contactdataDisplay'
                )"
            />
            <label class="form-check-label" for="setupContactRadio0">{{
              $t("REPORTING_SETUP_CONTACT_RADIO0")
            }}</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="setupContactRadios"
              id="setupContactRadio1"
              :disabled="activeWaveReadonly"
              :checked="reportingSetUp.contactdataDisplay == 1"
              :value="1"
              @change="
                updateReportingSetUp(
                  parseInt($event.target.value),
                  reportingSetUp,
                  'contactdataDisplay'
                )"
            />
            <label class="form-check-label" for="setupContactRadio1">{{
              $t("REPORTING_SETUP_CONTACT_RADIO1")
            }}</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="setupContactRadios"
              id="setupContactRadio2"
              :disabled="activeWaveReadonly"
              :checked="reportingSetUp.contactdataDisplay == 2"
              :value="2"
              @change="
                updateReportingSetUp(
                  parseInt($event.target.value),
                  reportingSetUp,
                  'contactdataDisplay'
                )"
            />
            <label class="form-check-label" for="setupContactRadio2">{{
              $t("REPORTING_SETUP_CONTACT_RADIO2")
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="reporting-setup-button-container global-padding pb-3 pt-3">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/appeal-management`
            )"
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/configurations`
            )"
        >
          {{ $t("NEXT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const Slider = defineAsyncComponent(() => import("@/components/helper/Slider"));
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);

export default {
  name: "ReportingSetUp",
  components: {
    Slider,
    Switch,
  },
  computed: {
    reportingSetUp() {
      return this.$store.getters["waves/loadReportingSetUp"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  async setup() {
    return {
      rerenderKey: 0,
    };
  },
  methods: {
    updateReportingSetUp(value, reportingSetUp, el) {
      reportingSetUp[el] = value;
      this.$store.dispatch("waves/setReportingSetUp", reportingSetUp);
    },
    disable() {
      let setupSliderContainers = document.getElementsByClassName(
        "setup-slider-container"
      );
      if (!this.reportingSetUp.display_target) {
        for (const el of setupSliderContainers) {
          el.classList.add("disabled");
        }
      } else {
        for (const el of setupSliderContainers) {
          el.classList.remove("disabled");
        }
      }
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("waves/loadReportingSetUp");
  },
  mounted() {
    this.disable();
    this.rerenderKey += 1;
  },
  updated() {
    if (this.rerenderKey === 0) {
      let radios = document.querySelectorAll(
        'input[name="setupContactRadios"]'
      );
      radios.forEach((radio) => {
        if (radio.value === this.reportingSetUp.contactdata_display) {
          radio.checked = true;
        }
      });
      this.rerenderKey += 1;
    }
  },
  watch: {
    "reportingSetUp.displayTarget"() {
      this.disable();
    },
  },
};
</script>

<style lang="scss" scoped>
.setup-question-container {
  display: flex;
  align-items: flex-start;
}

.setup-question-text {
  padding-right: 10px;
}

.setup-slider-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 0.7rem;

  .slider {
    margin-top: 0.7rem;
  }
}

.setup-slider-container.disabled {
  color: $secondary-light;
}

.setup-slider-text {
  width: 30%;
}

.setup-slider-percentage {
  width: 8%;
  text-align: right;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reporting-setup-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slider {
  width: 55%;
}

@media screen and (max-width: 768px) {
  .slider {
    width: 40%;
  }
}
</style>
